<template>
  <VaLayout class="bg-white h-full">
    <template #content>
      <!-- <div class="bg-white shadow rounded-lg p-6"> -->

      <div class="flex justify-center">
        <div class="w-[200px]">
          <VaImage fit="contain" src="\Skwain_Logo.png" class="max-h-32" />
        </div>
      </div>
      <h1 class="text-2xl text-center py-8">Healthcare Staff Management System</h1>
      <div class="p-4">
        <main class="h-full flex flex-row items-center justify-start mx-auto max-w-[420px]">
          <div class="flex flex-col items-start">
            <RouterView />
          </div>
        </main>
      </div>
    </template>
  </VaLayout>
</template>

<script lang="ts" setup>
import { useBreakpoint } from 'vuestic-ui'

const breakpoint = useBreakpoint()
</script>
