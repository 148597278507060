export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        icon: 'dashboard',
      },
    },
    {
      name: 'users',
      displayName: 'menu.users',
      meta: {
        icon: 'person_search',
      },
    },
    {
      name: 'add_profile',
      displayName: 'menu.add_profile',
      meta: {
        icon: 'person_add',
      },
    },
    {
      name: 'deployment',
      displayName: 'menu.deployment',
      meta: {
        icon: 'person_pin_circle',
      },
    },
    {
      name: 'feedback',
      displayName: 'menu.feedback',
      meta: {
        icon: 'feedback',
      },
    },
    {
      name: 'finance',
      displayName: 'menu.finance',
      meta: {
        icon: 'currency_rupee',
      },
    },
    {
      name: 'demographics',
      displayName: 'menu.demographics',
      meta: {
        icon: 'analytics',
      },
    },
  ] as INavigationRoute[],
}
