<template>
  <div class="profile-dropdown-wrapper">
    <VaDropdown v-model="isShown" :offset="[9, 0]" class="profile-dropdown" stick-to-edges>
      <template #anchor>
        <VaButton preset="secondary" color="textPrimary">
          <span class="profile-dropdown__anchor min-w-max">
            <slot />
            <VaAvatar :size="32" color="warning">{{ user }}</VaAvatar>
          </span>
        </VaButton>
      </template>
      <VaDropdownContent
        class="profile-dropdown__content md:w-60 px-0 py-4 w-full"
        :style="{ '--hover-color': hoverColor }"
      >
        <VaList>
          <VaListItem class="menu-item px-4 text-base cursor-pointer h-8" @click="logout">
            <VaIcon name="mso-logout" class="pr-1" color="secondary" />
            Logout
          </VaListItem>
        </VaList>
        <!-- <VaList v-for="group in options" :key="group.name">
          <header v-if="group.name" class="uppercase text-[var(--va-secondary)] opacity-80 font-bold text-xs px-4">
            {{ t(`user.${group.name}`) }}
          </header>
          <VaListItem v-for="item in group.list" :key="item.name" class="menu-item px-4 text-base cursor-pointer h-8"
            v-bind="resolveLinkAttribute(item)">
            <VaIcon :name="item.icon" class="pr-1" color="secondary" />
            {{ t(`user.${item.name}`) }}
          </VaListItem>
          <li v-if="isLoggedIn">
            <a id="logout-link" href="#" @click.prevent="logout">Logout</a>
          </li>

          <VaListSeparator v-if="group.separator" class="mx-3 my-2" />
        </VaList> -->
      </VaDropdownContent>
    </VaDropdown>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useColors } from 'vuestic-ui'
import AuthService from '../../../../pages/auth/services/AuthService'
import { useAuthStore } from '../../../../stores/auth-store'
import { useRouter } from 'vue-router'

const { colors, setHSLAColor } = useColors()
const hoverColor = computed(() => setHSLAColor(colors.focus, { a: 0.1 }))
const authStore = useAuthStore()
const { push } = useRouter()
const { t } = useI18n()
const user = ref({})
type ProfileListItem = {
  name: string
  to?: string
  href?: string
  icon: string
}

type ProfileOptions = {
  name: string
  separator: boolean
  list: ProfileListItem[]
}

withDefaults(
  defineProps<{
    options?: ProfileOptions[]
  }>(),
  {
    options: () => [
      {
        name: 'account',
        separator: true,
        list: [
          {
            name: 'profile',
            to: 'preferences',
            icon: 'mso-account_circle',
          },
          {
            name: 'settings',
            to: 'settings',
            icon: 'mso-settings',
          },
        ],
      },
      {
        name: '',
        separator: false,
        list: [
          {
            name: 'logout',
            to: 'login',
            icon: 'mso-logout',
          },
        ],
      },
    ],
  },
)

const isShown = ref(false)

const resolveLinkAttribute = (item: ProfileListItem) => {
  return item.to ? { to: { name: item.to } } : item.href ? { href: item.href, target: '_blank' } : {}
}
onMounted(() => {
  console.log(authStore.token)
  if (authStore.token != '') {
    AuthService.getLoggedUser()
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        user.value = data.data.name.slice(0, 1)
      })
      .catch((error) => {
        console.error(error)
      })
  }
})
const logout = (evt: any) => {
  if (confirm('Are you sure you want to log out?')) {
    AuthService.logout()
      .then((response) => response.json())
      .then((data) => {
        authStore.updateToken('')
        // setTimeout(() => {
        push({ name: 'login' })
        // }, 500)
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
</script>

<style lang="scss">
.profile-dropdown {
  cursor: pointer;

  &__content {
    .menu-item:hover {
      background: var(--hover-color);
    }
  }

  &__anchor {
    display: inline-block;
  }
}
</style>
