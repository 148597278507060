import { useAuthStore } from '../../../stores/auth-store'
const ApiBaseUrl = import.meta.env.VITE_BASE_URL

/* eslint-disable */
class AuthService {
  login(req: any): Promise<any> {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    const raw = JSON.stringify(req)
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }
    return fetch(ApiBaseUrl + '/api/auth/login', requestOptions)
  }
  logout(): Promise<any> {
    const myHeaders = new Headers()
    // const authStore = useAuthStore();
    const auth = JSON.parse(localStorage.getItem('auth'))
    const token = auth?.token
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Accept', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + token)
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: {},
    }
    return fetch(ApiBaseUrl + '/api/auth/logout', requestOptions)
  }
  getLoggedUser(): Promise<any> {
    const myHeaders = new Headers()
    // const authStore = useAuthStore();
    const auth = JSON.parse(localStorage.getItem('auth'))
    const token = auth?.token
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Accept', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + token)
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: {},
    }
    return fetch(ApiBaseUrl + '/api/auth/profile', requestOptions)
  }
}

export default new AuthService()
